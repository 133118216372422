import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://api.moonrails.mc.hzuccon.com/api'
})

export const isAuthenticated = () => {
  return instance.defaults.headers.common['Authorization'] !== undefined
}

export const fetchAccessToken = async () => {
  const { data } = await instance.get('/v1/getAccessToken')
  if (!data.success) throw new Error('Could not fetch access token')
  
  return data.access_token
}

export const setAccessToken = (accessToken) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export default instance